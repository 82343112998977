<template>

  <div id="infinite-list">

      <Competition
          v-show="!highlights"
          v-for="(l,index) in competitions"
          v-bind:key="getLeagueKey(l,index)"
          v-bind:competition="l"
          v-bind:is_visible="true"
          v-bind:upcoming="upcoming"
          v-bind:today="today"
          v-bind:esport="esport"
          v-bind:highlights="highlights"
          v-bind:live="live"
          v-bind:date="date">
      </Competition>

      <Highlight
          v-show="highlights"
          v-for="(l,index) in highlightsData"
          v-bind:key="getLeagueKey(l,index)"
          v-bind:fixture="l">
      </Highlight>

    <scroll-loader v-show="highlights" :loader-method="loadOnScroll" :loader-disable="is_busy">
      <div>Loading...</div>
    </scroll-loader>
  </div>

</template>

<script>

const Competition = () => import('./Competition.vue')
const Highlight = () => import('./Highlight.vue')
import Vue from 'vue'
import ScrollLoader from 'vue-scroll-loader'
Vue.use(ScrollLoader)

import fixtures from "@/services/fixtures";
import mqtt from 'mqtt';

export default {
  name: "Games",
  mounted: function () {

    var vm = this;
    this.resetFilters();

    vm.market_outcome_headers = this.$store.state.market_outcome;

    this.is_busy = true;
    vm.visible_leagues = [];

    // keep a list of openned/expanded leagues
    this.EventBus.$on('event:leagues:show', function (id) {

      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {

        if (parseInt(v) === parseInt(id)) {

          exists = true;

        }

      })

      if (!exists) {

        vm.visible_leagues.push(parseInt(id));

      }

    });

    this.initMqtt(this.sport_id);

    this.$nextTick(function () {

      vm.autoRefreshUI(this.$vnode.tag);

    });

    //setInterval(function (){

    if (vm.searchable) {

      if (vm.search.length > 0) {

        vm.getSearch();

      } else {

        vm.is_busy = false;

      }

    } else if (vm.live) {

      vm.getLive();

    } else if (vm.esport) {

      vm.getEsport();

    } else if (vm.highlights) {

      vm.is_busy = false;
      vm.getHighlight();

    } else if (vm.today) {

      vm.is_busy = false;
      vm.getTodayFixtures();

    } else if (vm.leo) {

      vm.live = true;
      vm.getAllLive();

    } else {

      vm.getCompetitions();

    }

    //},1000 * 30);

  },
  methods: {
    loadOnScroll: function(){

      var vm = this;

      if (vm.highlights) {

        vm.getHighlight();

      } else if (vm.today) {

        // vm.getTodayFixtures();
      }

    },

    initMqtt: function () {

      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      //var market_id = this.market_id == 0 ? 1 : this.market_id;
      //var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      //var topic_betstop = 'topic/producer-3/sport-'+sport_id+'/status/+/market-'+market_id;
      //var topic_others = 'topic/producer-3/sport-'+sport_id+'/betstop/+';
      //var topic_match = 'topic/producer-3/sport-'+sport_id+'/match/+';
      //var topic_match = 'topic/producer-3/sport-'+sport_id+'/status/+';
      //var topic_producer = 'topic/producer-3';

      if (this.live) {

        //topic_others = 'topic/producer-1/sport-'+sport_id+'/betstop/+';
        //topic_betstop = 'topic/producer-1/sport-'+sport_id+'/status/+/market-'+market_id;
        //topic_match = 'topic/producer-1/sport-'+sport_id+'/status/+';
        //topic_producer = 'topic/producer-1';

      }

      var client = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST, options);

      client.on('connect', function () {

        /*
        client.subscribe(topic_betstop, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_betstop);

          }
        })

        client.subscribe(topic_others, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_others);

          }
        })


        client.subscribe(topic_match, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_match);

          }
        })
         */

        var topic2 = 'topic/producer';
        client.subscribe(topic2, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic2);

          }
        })

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

    },

    getKey: function (fixture, index) {

      if (Array.isArray(fixture)) {

        var currentFixture = fixture[0];

      } else {

        currentFixture = fixture

      }

      var prefix = currentFixture === undefined || currentFixture.match_id == undefined ? index : currentFixture.match_id;
      return Math.random().toString(10).replace('0.', 'fixture-id-' + prefix + '-');

    },

    getLeagueKey: function (league, index) {

      var prefix = league === undefined || league.competition_id == undefined ? index : league.competition_id;
      return Math.random().toString(10).replace('0.', 'competition-id-' + prefix + '-');

    },

    getCompetitions: function () {

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {

        endpoint = process.env.VUE_APP_URL_LIVE_TOP_LEAGUES;

      }

      if (this.esport) {

        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;

      }

      endpoint = "/tournaments/{sport_id}"

      var path = endpoint.replace("{sport_id}", parseInt(vm.sport_id) > 0 ? vm.sport_id : 1);
      path = path.replace("{count}", 0)

      var games_filter = {};

      if (this.date.length > 0) {

        games_filter.date = this.date;

      }

      if (parseInt(this.upcoming) > 0) {

        games_filter.upcoming = this.upcoming;
        games_filter.highlights = ''

      }

      games_filter.hour = vm.hour;
      games_filter.hours = vm.hour;

      fixtures.get(path)
          .then(res => {

            var leagues = res.data.data;

            if (vm.highlight || vm.highlight) {

              vm.jQuery.each(leagues, function (k, v) {

                v.competition_id = v.tournament_id
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;

                var exist = false;

                // check if league exists in current leagues
                vm.jQuery.each(vm.leagues, function (kk, vv) {

                  if (vv.competition_id === v.competition_id) {

                    exist = true;
                  }

                })

                if (!exist) {

                  vm.leagues.push(v)

                }

              });

            } else {

              vm.leagues = leagues;
              vm.jQuery.each(vm.leagues, function (k, v) {
                v.competition_id = v.tournament_id
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;
                vm.leagues[k] = v;
              });

            }

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.getGames();
            vm.autoRefreshUI(vm.$vnode.tag);

          })
          .catch(error => {

            console.log(error)

          })

    },
    
    getFixture: function (competitions) {

      var vm = this;

      var path = process.env.VUE_APP_URL_FIXTURES_COMPETITIONS;

      var payload = {
        highlights: 1,
        upcoming: 0,
        hours: this.hour,
        country_code: "",
        date: vm.date,
        keyword: "",
        per_page: 100,
        competitions: competitions,
        //page: 1,
        highlight_market_id: vm.market_id,
        //match_live_status: 0,
        //tournament_id: vm.competition_id,
        //category_id: vm.category_id,
      };

      if (this.highlights) {

        path = process.env.VUE_APP_URL_HIGHLIGHTS;

      } else if (this.today) {

        path = process.env.VUE_APP_URL_TODAYS_COMPETITIONS;

      } else if (this.live) {

        path = process.env.VUE_APP_URL_LIVE_ODDS;

      }

      path = "/highlights/{sport_id}"
      
      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{competition_id}", 0);
      path = path.replace("{market_id}", vm.market_id);
      path = path.replace("{page}", 1);


      if (this.date.length > 0) {

        payload.date = this.date;

      }

      if (parseInt(this.upcoming) > 0) {

        payload.upcoming = this.upcoming;
        payload.highlights = ''

      }


      fixtures.get(path, {params: payload})
          .then(res => {

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = res.data.data;

            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                vv.competition_id = vv.tournament_id;
                
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })
            vm.competitions = vm.leagues;
            vm.autoRefreshUI(vm.$vnode.tag);

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getTodayFixturesOld: function () {

      console.log('getTodayFixtures here ')
      var vm = this;

      var path = "/highlights/{sport_id}"// process.env.VUE_APP_URL_HIGHLIGHTS_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {

        vm.is_busy = false;
        console.log('Current page '+vm.page+' but reached end of page ');
        return;

      }

      if(vm.is_busy) {

        console.log('Current page '+vm.page+' but is busy ');
        return;

      }

      console.log('Current page '+vm.page);
      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy',true);

      var top_leagues = new Object();

      //{params: payload}

      let payload = {
        hours: vm.hour,
        page: vm.page,
        per_page: 20,//vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        //category_id: vm.category_id,
        upcoming: 0,
        boosted: 0,
        today: 1,
      }

      fixtures.get(path, {params: payload})
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy',false);

            var highlightsRawData = res.data.data;
            vm.last_page = res.data.last_page;
            vm.remaining_records = res.data.remaining_records;

            if(parseInt(vm.page) === 1) {

              vm.highlightsRawData = [];

            }

            if(highlightsRawData.length === 0 ) {

              vm.last_page = vm.page - 1;

            }

            console.log("highlightsRawData.length === "+highlightsRawData.length);


            vm.jQuery.each(highlightsRawData, function (k, v) {

              v.competition_id = v.tournament_id;
              vm.highlightsRawData.push(v);

            });

            vm.filterHighlight()

            //vm.market_outcome_headers = res.data.message.headers;
            // top leagues

            vm.jQuery.each(vm.highlightsRawData, function (k, v) {

              var tournament = v.tournament;
              var competition_id = v.tournament_id;
              var country = v.country;

              var ob = {
                country_name: country,
                competition_id: competition_id,
                competition: country + '/' + tournament
              }

              top_leagues["c_" + competition_id] = ob

            });

            var t_l = [];
            vm.jQuery.each(top_leagues, function (k, v) {

              t_l.push(v)

            });

            vm.top_leagues = t_l;
            vm.$store.dispatch("setLeague", vm.top_leagues);

          })

          .catch(err => {

            vm.EventBus.$emit('event:busy',false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getAllLive: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_LIVE_ALL;

      path = path.replace("{sport_id}", 0);

      fixtures.post(path, JSON.stringify({}))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getSearch: function () {
      var vm = this;
      if (this.is_busy || (this.page > 0 && this.page > this.last_page)) return;
      vm.leagues = [];
      vm.competitions = [];

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/search/{sport_id}';
      path = path.replace("{sport_id}", 0);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      // Log the current page before increment
      // console.log("Current Page Before Increment:", vm.page);

      vm.page = parseInt(vm.page) + 1;

      // Log the new page after increment
      // console.log("New Page After Increment:", vm.page);

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        sport_id: parseInt(0),
        page: parseInt(vm.page),
        per_page: parseInt(vm.per_page),
        highlight_market_id: parseInt(vm.market_id),
        search: String(vm.search),
      };

      // Log the request data
      // console.log("Request Data:", dat);

      fixtures.get(path, { params: dat })
        .then((res) => {
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          // Log the response data
          // console.log("Response Data:", res.data);

          if (parseInt(vm.page) === 1) {
            console.log("Resetting fixtures array");
            vm.fixtures = []; // Reset fixtures array
          }

          highlightsRawData.forEach((highlight) => {
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });

          vm.is_busy = false;
        })
        .catch((err) => {
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message.error,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },
    
    getHighlight: function () {

      var vm = this;

      var path = "/highlights/{sport_id}"// process.env.VUE_APP_URL_HIGHLIGHTS_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {

        vm.is_busy = false;
        console.log('Current page '+vm.page+' but reached end of page ');
        return;

      }

      if(vm.is_busy) {

        console.log('Current page '+vm.page+' but is busy ');
        return;

      }

      console.log('Current page '+vm.page);
      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy',true);

      var top_leagues = new Object();

      let payload = {
        page: 1,//vm.page,
        per_page: 20,//vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        //category_id: vm.category_id,
        upcoming: 0,
        boosted: 0,
        today: 0,
        //hours: vm.hour,
      }

      fixtures.get(path, {params: payload})
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy',false);

            var highlightsRawData = res.data.data;
            vm.last_page = res.data.last_page;
            vm.remaining_records = res.data.remaining_records;

            if(parseInt(vm.page) === 1) {

              vm.highlightsRawData = [];

            }

            if(highlightsRawData.length === 0 ) {

              vm.last_page = vm.page - 1;

            }

            console.log("highlightsRawData.length === "+highlightsRawData.length);


            vm.jQuery.each(highlightsRawData, function (k, v) {

              v.competition_id = v.tournament_id;
              vm.highlightsRawData.push(v);

            });

            vm.filterHighlight()

            //vm.market_outcome_headers = res.data.message.headers;
            // top leagues

            vm.jQuery.each(vm.highlightsRawData, function (k, v) {

              var tournament = v.tournament;
              var competition_id = v.tournament_id;
              var country = v.country;

              var ob = {
                country_name: country,
                competition_id: competition_id,
                competition: country + '/' + tournament
              }

              top_leagues["c_" + competition_id] = ob

            });

            var t_l = [];
            vm.jQuery.each(top_leagues, function (k, v) {

              t_l.push(v)

            });

            vm.top_leagues = t_l;
            vm.$store.dispatch("setLeague", vm.top_leagues);

          })

          .catch(err => {

            vm.EventBus.$emit('event:busy',false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getTodayFixtures: function () {

      var vm = this;

      var path = "/highlights/{sport_id}"// process.env.VUE_APP_URL_HIGHLIGHTS_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {

        vm.is_busy = false;
        console.log('Current page '+vm.page+' but reached end of page ');
        return;

      }

      if(vm.is_busy) {

        console.log('Current page '+vm.page+' but is busy ');
        return;

      }

      console.log('Current page '+vm.page);
      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy',true);

      var top_leagues = new Object();

      let payload = {
        page: 1,//vm.page,
        per_page: 20,//vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        //category_id: vm.category_id,
        upcoming: 0,
        boosted: 0,
        today: 1,
        //hours: vm.hour,
      }

      fixtures.get(path, {params: payload})
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy',false);

            var highlightsRawData = res.data.data;
            vm.last_page = res.data.last_page;
            vm.remaining_records = res.data.remaining_records;

            if(parseInt(vm.page) === 1) {

              vm.highlightsRawData = [];

            }

            if(highlightsRawData.length === 0 ) {

              vm.last_page = vm.page - 1;

            }

            console.log("highlightsRawData.length === "+highlightsRawData.length);


            vm.jQuery.each(highlightsRawData, function (k, v) {

              v.competition_id = v.tournament_id;
              vm.highlightsRawData.push(v);

            });

            vm.filterHighlight()

            //vm.market_outcome_headers = res.data.message.headers;
            // top leagues

            vm.jQuery.each(vm.highlightsRawData, function (k, v) {

              var tournament = v.tournament;
              var competition_id = v.tournament_id;
              var country = v.country;

              var ob = {
                country_name: country,
                competition_id: competition_id,
                competition: country + '/' + tournament
              }

              top_leagues["c_" + competition_id] = ob

            });

            var t_l = [];
            vm.jQuery.each(top_leagues, function (k, v) {

              t_l.push(v)

            });

            vm.top_leagues = t_l;
            vm.$store.dispatch("setLeague", vm.top_leagues);

          })

          .catch(err => {

            vm.EventBus.$emit('event:busy',false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    filterHighlight: function () {

      var vm = this;

      vm.highlightsData = [];

      if (parseInt(vm.competition_id) < 1 ) {

        vm.highlightsData = vm.highlightsRawData;
        return;

      }

      vm.jQuery.each(vm.highlightsRawData, function (k, v) {

        if (parseInt(vm.competition_id) === parseInt(v.competition_id)) {

          vm.highlightsData.push(v);
        }

      });

    },
    getEsport: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_ESPORT_SPORT;

      path = path.replace("{sport_id}", 1);

      fixtures.post(path, JSON.stringify({}))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    getLive: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_LIVE_SPORT;
      //var path = process.env.VUE_APP_URL_LIVE;

      path = path.replace("{sport_id}", 1);

      fixtures.post(path, JSON.stringify({}))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    getGames: function () {

      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {

        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {

          competitions.push(competition_id);

        }

      })

      vm.getFixture(competitions.join(','));

    },
    reloadUI: function () {

      this.autoRefreshUI(this.$vnode.tag);

    },
  },
  computed: {
    loadingDistance: function () {

      return 50

    },
    market_outcome: function () {

      return this.market_outcome_headers;
    },
    games: function () {

      return this.$store.state.games
    },
    allLoaded: function () {

      return this.$store.state.games.current_page > 0 && this.$store.state.games.current_page === this.$store.state.games.last_page
    },
    busy1: function () {

      return this.$store.state.busy
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
    market_id: function () {

      return this.$store.state.market_id;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
    hour: function () {

      return this.$store.state.hour;
    }
  },
  components: {
    Competition,
    Highlight,
  },
  data: function () {
    return {
      leagues: [],
      top_leagues: [],
      competitions: [],
      highlightsData: [],
      highlightsRawData: [],
      loading: [],
      visible: [],
      busy: [],
      is_busy: false,
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      per_page: 20,
      page: 0,
      last_page: 0,
      remaining_records: 0,

    }
  },
  props: {
    date: {
      required: false,
      default: ""
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },
    upcoming: {
      required: false,
      default: false
    },
    highlights: {
      required: false,
      default: false
    },
    live: {
      required: false,
      default: false
    },
    today: {
      required: false,
      default: false
    },
    leo: {
      required: false,
      default: false
    },
    sport: {
      required: false,
      default: 1
    },
    esport: {
      required: false,
      default: false
    },
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if(parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      if (parseInt(oldValue) > 0 && parseInt(newValue) !== parseInt(oldValue) ) {

        this.per_page =  20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;

        if (this.highlights) {

          this.highlightsRawData = [];
          this.getHighlight();

        } else {

          this.getCompetitions();

        }

        this.initMqtt();

      }

    },
    market_id: function (newValue, oldValue) {

      console.log('Hour =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);
      if(parseInt(newValue) === parseInt(oldValue)) {

        return;
      }

      if (this.highlights) {

        this.per_page =  20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.getHighlight();

      } else {

        if (this.visible_leagues.length > 0) {

          this.competitions = [];
          this.getFixture(this.visible_leagues.join(','));

        }

      }
    },
    hour: function (newValue, oldValue) {

      console.log('Hour =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      if(parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      if (this.highlights) {

        this.per_page =  20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.getHighlight();

      } else {

        this.getCompetitions();

      }
    },
    search: function (newValue, oldValue) {

      console.log('search =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      if (newValue !== oldValue && newValue.length > 0) {

        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {

      console.log('date newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      this.getCompetitions();
    },
    competition_id: function (newValue, oldValue) {

      if(parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      if (this.highlights) {

        this.filterHighlight();

      } else {

        if (this.visible_leagues.length > 0) {

          this.competitions = [];
          this.getFixture(this.visible_leagues.join(','));

        }

      }

    },
  },
  beforeDestroy: function () {
    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }
  }
}

</script>